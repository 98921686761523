body {
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    overflow: hidden;
  }

  h1 {
    margin: 10px 0;
    text-align: center;
  }

  h2 {
    margin-top: 50px;
    text-align: center;
  }

  p {
    text-align: center;
  }

  .block {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
  }
  
  .nav {
    list-style: none;
    padding: 0;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }

  .nav li {
    display: inline-block;
  }
  
  .nav-item-icon {
    margin: 10px;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column; 
  }

  .center button {
    margin-top: 20px;
  }
  
  .center button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .center button:hover {
    background-color: #2980b9;
  }
  